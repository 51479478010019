<template>
  <HostLayoutContainer class="certification" :style="{height:'340px'}">
    <HostLayoutItem style="position: relative">
      <div class="certification__title" id="certification">安全、可靠、合规的云服务</div>

      <CardsRow>
        <Row class="matrix__row">
          <Item class="matrix__item">
            <Card5>
              <template #logo><img src="/assets/home/certification_1@3x.png" alt=""></template>
              <template #title>ISO9001</template>
            </Card5>
          </Item>
          <Item class="matrix__item">
            <Card5>
              <template #logo><img src="/assets/home/certification_2@3x.png" alt=""></template>
              <template #title>ISO20000</template>
            </Card5>
          </Item>
          <Item class="matrix__item">
            <Card5>
              <template #logo><img src="/assets/home/certification_3@3x.png" alt=""></template>
              <template #title>ISO27001</template>
            </Card5>
          </Item>
          <Item class="matrix__item">
            <Card5>
              <template #logo><img src="/assets/home/certification_4@3x.png" alt=""></template>
              <template #title>可信云多云认证</template>
            </Card5>
          </Item>
          <Item class="matrix__item">
            <Card5>
              <template #logo><img src="/assets/home/certification_5@3x.png" alt=""></template>
              <template #title>可信云混合云认证</template>
            </Card5>
          </Item>
          <Item class="matrix__item">
            <Card5>
              <template #logo><img src="/assets/home/certification_6@3x.png" alt=""></template>
              <template #title>国家级高新技术企业</template>
            </Card5>
          </Item>
          <Item class="matrix__item">
            <Card5>
              <template #logo><img src="/assets/home/certification_7@3x.png" alt=""></template>
              <template #title>双软认证</template>
            </Card5>
          </Item>
        </Row>
      </CardsRow>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import { defineComponent } from 'vue'
import CardsRow from '@/components/cards/cards-row.vue'
import Row from '@/components/cards/row.vue'
import Item from '@/components/cards/item.vue'
import Card5 from '@/components/cards/card5.vue'
export default defineComponent({
  name: 'certification',
  components: {
    CardsRow,
    Row,
    Item,
    Card5,
  }
})
</script>

<style lang="scss" scoped>
.certification__title {
  display: flex;
  justify-content: center;
  margin-bottom: 78px;

  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 45px;
  letter-spacing: 2px;
}
.matrix__row {
  display: flex;
  justify-content: space-around;
}
</style>
