<template>
  <div class="card__logo">
    <div class="card__logo-inner">
      <slot name="logo" />
    </div>
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
.card__logo {
  display: flex;
  justify-content: center;

  width: 173px;
  height: 93px;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px 0px rgba(185, 185, 185, 0.38);
  border-radius: 4px;
}
</style>
