<template>
  <HostLayoutContainer
    id="introduce"
    class="introduce"
    :style="{ height: '438px' }"
  >
    <HostLayoutItem style="position: relative; height: 438px">
      <div class="introduce__title">公司介绍</div>
      <div class="introduce__desc">
        <div class="introduce__desc-text" @click="goToSolution">了解更多</div>
      </div>

      <div class="introduce__card introduce__general">
        <img
          src="/assets/home/logo_introduce@3x.png"
          alt=""
          class="introduce__card-logo"
        />
        <div class="introduce__content">
          <div class="introduce__content-text">
            Ubang于2016
            年进入公有云服务领域，为中国出海企业提供选云、用云的全流程全周期服务，累计服务百余家中国出海企业，积累了丰富的服务经验。
          </div>
          <div class="introduce__content-text">
            Ubang在2021年成为微软合作伙伴，在中国大陆及中国香港地区分销微软在线服务产品。
          </div>
        </div>
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import CardsColumn from '@/components/cards/cards-column.vue'
import Column from '@/components/cards/column.vue'
import Row from '@/components/cards/row.vue'
import Item from '@/components/cards/item.vue'
import Card2 from '@/components/cards/card2.vue'
import Card3 from '@/components/cards/card3.vue'
export default defineComponent({
  components: {
    CardsColumn,
    Column,
    Row,
    Item,
    Card2,
    Card3,
  },
  setup () {
    const router = useRouter()
    const goToSolution = () => { }
    return {
      goToSolution
    }
  }
})
</script>

<style lang="scss" scoped>
.introduce {
  background-image: url("/assets/home/banner_introduce@3x.png");
}
.introduce__title {
  margin-top: 139px;
  margin-left: 62px;
  margin-bottom: 60px;

  font-size: 65px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 91px;
  text-shadow: 0px 0px 55px #0d253e;
}
.introduce__desc {
  width: 371px;
  height: 70px;
  background: #ffcc01;
  box-shadow: 0px 0px 22px 0px rgba(254, 203, 1, 0.4);
  border-radius: 4px;
}
.introduce__desc-text {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 22px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 70px;
  letter-spacing: 1px;

  cursor: pointer;
}

.introduce__card {
  box-sizing: border-box;
  padding: 81px 57px 0 39px;

  background: #ffffff;
  box-shadow: 0px 0px 60px 10px rgba(13, 37, 62, 0.1);
  border-radius: 4px;
}
.introduce__general {
  position: absolute;
  top: -35px;
  right: -85px;

  width: 660px;
  height: 507px;
}
.introduce__card-logo {
  margin-bottom: 27px;
  width: 173px;
  height: 65px;
}
.introduce__content-text:nth-child(1) {
  margin-bottom: 25px;
}
.introduce__content-text {
  font-size: 20px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #000c42;
  line-height: 38px;
}
</style>
