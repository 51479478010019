<template>
  <div class="item" :style="{width}">
    <slot />
  </div>
</template>

<script>
// import { framework } from ''
export default {
  props: ['width'],
  setup(props) {
    const width = props.width
    return {
      width
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  // display: flex;
  // justify-content: center;
}
</style>
