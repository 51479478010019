<template>
  <div class="card">
    <div class="card__row">
      <div class="card__number">
        <slot name="number" />
      </div>
      <div class="card__title">
        <slot name="title" />
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
export default defineComponent({
})
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card__row {
  display: flex;
}
.card__number {
  margin-right: 20px;

  width: 40px;
  height: 51px;
  font-size: 37px;
  font-family: Akrobat-Black, Akrobat;
  font-weight: 900;
  color: #1E88F5;
  line-height: 45px;
}
.card__title {
  margin-top: 16px;

  height: 46px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D1A26;
  line-height: 23px;
}
</style>
