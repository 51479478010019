<template>
  <HostLayoutContainer class="client" :style="{height:'820px'}">
    <HostLayoutItem style="position: relative;height:820px">
      <div class="client__title" id="client">我们的客户</div>
      <CardsRow :style="{height: '420px'}">
        <Row class="matrix__row">
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_1@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_2@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_3@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_4@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_5@3x.png" alt=""></template>
            </Card4>
          </Item>
        </Row>
        <Row class="matrix__row">
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_6@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_7@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_8@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_9@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_10@3x.png" alt=""></template>
            </Card4>
          </Item>
        </Row>
        <Row class="matrix__row">
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_11@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_12@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_13@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_14@3x.png" alt=""></template>
            </Card4>
          </Item>
          <Item class="matrix__item">
            <Card4>
              <template #logo><img src="/assets/home/client_15@3x.png" alt=""></template>
            </Card4>
          </Item>
        </Row>
      </CardsRow>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import { defineComponent } from 'vue'
import CardsRow from '@/components/cards/cards-row.vue'
import Row from '@/components/cards/row.vue'
import Item from '@/components/cards/item.vue'
import Card4 from '@/components/cards/card4.vue'
export default defineComponent({
  name: 'Client',
  components: {
    CardsRow,
    Row,
    Item,
    Card4,
  }
})
</script>

<style lang="scss" scoped>
.client__title {
  display: flex;
  justify-content: center;
  margin-top: 152px;
  margin-bottom: 78px;

  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 45px;
  letter-spacing: 2px;
}
.matrix__row {
  display: flex;
  justify-content: space-around;
}
</style>
