<template>
  <div class="card">
    <div v-if="ellipsis" class="card__ellipsis">...</div>
    <div class="card__placeholder"></div>
    <div v-if="go" class="card__go" @click="goToPath"><img src="/assets/home/arrow@3x.png" alt=""></div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {useRouter} from 'vue-router'
export default defineComponent({
  props: {
    ellipsis: {
      default: false
    },
    go: {
      default: false
    },
    to: {
      default: () => ({path: '/', hash: ''})
    },
  },
  setup(props) {
    const router = useRouter()

    const {logo = {}, title = {}} = props;
    let {size, width, height, marginBottom} = logo
    if (size) {
      width = size
      height = size
    }

    const goToPath = () => {
      const { path, hash } = props.to
      router.push({ path, hash })
    }
    return {
      logo: {width, height, marginBottom},
      title,
      goToPath,
    }
  }
})
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 15px;
  height: 30px;
}
.card__ellipsis {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D1A26;
  line-height: 33px;
}
.card__go {
  width: 31px;
  height: 15px;
  cursor: pointer;
}
</style>
