<template>
  <div class="card__logo">
    <slot name="logo" />
  </div>
  <div class="card__title">
    <slot name="title" />
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
.card__logo {
  display: flex;
  justify-content: center;

  width: 131px;
  height: 102px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px 0px rgba(184, 199, 237, 0.38);
  border-radius: 3px;
}
.card__title {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #2E4068;
  line-height: 20px;
}
</style>
